/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';

import { IFormEtiqueta, IEtiqueta } from '~/pages/RegraDeLoja/types';

const Etiqueta = () => {
  const [etiquetas, setEtiquetas] = useState<IEtiqueta[]>([]);
  const { formRegraLoja, changeFormRegraLoja } = useRegraLoja();

  useEffect(() => {
    const regs = formRegraLoja.Etiquetas.etiquetas.map(
      (item: IFormEtiqueta) => {
        return {
          id: item.nome_pc.value,
          cod_parametro_etiqueta: item.cod_parametro_etiqueta.value,
          des_parametro: item.des_parametro.value,
          nome_pc: item.nome_pc.value,
          tipo_porta: item.porta.value.value,
          tipo_linguagem: item.linguagem.value.value,
          val_temperatura: item.temperatura.value,
          tipo_dpi: item.dpi.value.value,
          val_margem_esquerda: item.margem_esquerda.value,
          val_velocidade: item.velocidade.value,
          tipo_pagina_codigo: item.pagina_codigo.value.value,
        };
      },
    );
    setEtiquetas(regs);
  }, [formRegraLoja.Etiquetas.etiquetas]);

  const etiquetaColumns = [
    {
      field: 'des_parametro',
      headerName: 'Nome Impressora',
      minWidth: 230,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: 'nome_pc',
      headerName: 'Nome Computador',
      width: 230,
      flex: 1,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      <div className="row">
        <div
          className="col-sm-12 col-md-12"
          style={{ height: 380, width: '100%', marginTop: '16px' }}
        >
          <DataGrid
            rows={etiquetas}
            columns={etiquetaColumns}
            pageSize={5}
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            justifyContent: 'flex-end',
          }}
        >
          <a
            href="https://konvix.zendesk.com/hc/pt-br"
            target="_blank"
            className="linkMaterial"
          >
            Como configurar uma impressora de etiquetas?
          </a>
        </div>
      </div>
    </>
  );
};

export default Etiqueta;
